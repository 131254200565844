.Locations{
    padding-bottom: 50px;
}
.LocationsBG{
    height: 250px;
    width: 100%;
    background: url("../../assets/img/RelationshipBased/RelationshipBasedBG.webp") center no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}
.LocationsBG:after{
    position: absolute;
    content: '';
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.LocationsBG h3{
    z-index: 1;
    margin-left: 11%;
    color:white;
    font-size: 36px;
}

.mainOffice{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
}
.Title{
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;
}
.mainOffice img{
    margin-bottom: 30px;
    width: 20%;
}
.city{
    text-transform: uppercase;
}
.regTitle{
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
}
.regionalOffices {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 50px;
}

.regOffice {
    margin-top: 50px;
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 200px;
}

.regOfficeCity {
    text-transform: uppercase;
    margin-bottom: 20px;
}

.regOffice img {
    max-height: 40%;
}
.regOfficeAddress {
    margin-top: 20px;
    text-align: start;
}
.addressLink:hover{
    transition: all 0.2s ease-in;
    color: #ffd400;
}
@media (max-width: 1080px) {
    .regionalOffices{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 780px) {
    .mainOffice img{
        width: 50%;
    }
    .regOfficeAddress{
        margin: 40px 0 0 0;
    }
}
@media (max-width: 480px) {
    .phone, .email{
        align-self: flex-start;
    }
    .regOffice{
        height: auto;
    }
    .LocationsBG{
        height: 175px;
    }
    .LocationsBG h3{
        font-size: 24px;
    }
    .LocationsBG:after{
        height: 100%;
    }
}