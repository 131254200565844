.Network{
    padding-bottom: 30px;
}
.NetworkBG{
    height: 250px;
    width: 100%;
    background: url("../../assets/img/Network/Network.jpg") center no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}
.NetworkBG:after{
    position: absolute;
    content: '';
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.NetworkBG h3{
    z-index: 1;
    margin-left: 11%;
    color:white;
    font-size: 36px;
}


.Text{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid gray;
}
.Desc{
    max-width: 700px;
}
.Desc a{
    text-decoration: underline;
}
.Desc a:hover{
    transition: all 0.2s ease-in;
    text-decoration: none;
    color: #ffea00;
}
.Desc span{
    color: #ffea00;
}
.Text h4{
    margin-top: 20px;
}
.Text p{
    margin-top: 10px;
}
