.CostReduction{
    padding-bottom: 30px;
}
.CostReductionBG{
    height: 250px;
    width: 100%;
    background: url("../../assets/img/CostReduction/CostReductionBG.webp") no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}
.CostReductionBG:after{
    position: absolute;
    content: '';
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.CostReductionBG h3{
    z-index: 1;
    margin-left: 11%;
    color:white;
    font-size: 36px;
}

.Wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.Text{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid gray;
}
.Text h4{
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}
@media (max-width: 900px) {
    .Flex{
        font-size: 15px;
        align-items: center;
    }
}
@media (max-width: 700px) {
    .Flex{
        flex-wrap: wrap;
        justify-content: center;
    }
    .FlexTitle{
        text-align: center;
    }
}
@media (max-width: 480px) {
    .CorporateSupportBG{
        height: 175px;
    }
    .CorporateSupportBG h3{
        font-size: 24px;
    }
    .Desc {
        font-size: 15px;
    }
    .FlexTitle{
        font-size: 14px;
    }
    .FlexText p{
        font-size: 13px;
    }
    .CorporateSupportBG:after{
        height: 100%;
    }
}