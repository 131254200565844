.EthicsCompliance{
    padding-bottom: 30px;
}
.EthicsComplianceBG{
    height: 250px;
    width: 100%;
    background: url("../../assets/img/EthicsCompliance/EthicsComplianceBG.webp") center no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}
.EthicsComplianceBG:after{
    position: absolute;
    content: '';
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.EthicsComplianceBG h3{
    z-index: 1;
    margin-left: 11%;
    color:white;
    font-size: 36px;
}


.Text{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 30px;
    border-bottom: 1px solid gray;
}
.Text h4{
    margin-top: 20px;
}
.Text p{
    margin-top: 10px;
}
.Flex{
    display: flex;
    align-items: center;

    gap: 40px;
    margin-top: 30px;

}
.FlexText{
    width: 60%;
}
.FlexImg{
    width: 40%;
}

.Flex2{
    flex-direction: row-reverse;
}