.Services{
    padding-bottom: 30px;
}
.ServicesBG{
    height: 250px;
    width: 100%;
    background: url("../../assets/img/Services/ServicesBG.webp") center no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}
.ServicesBG:after{
    position: absolute;
    content: '';
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.ServicesBG h3{
    z-index: 1;
    margin-left: 11%;
    color:white;
    font-size: 36px;
}

.title{
    font-size: 24px;
    text-align: center;
    margin: 40px 0 40px 0;
}
.parent {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    height: 40vh;
}

.div1 { grid-area: 1 / 1 / 4 / 4; }
.div2 { grid-area: 1 / 4 / 4 / 6; }
.div3 { grid-area: 4 / 1 / 6 / 6; }


.div1, .div2, .div3, .div4 {
    position: relative;
    overflow: hidden;
}

.div1 img, .div2 img, .div3 img, .div4 img {
    content: '';
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    transition: all 0.7s ease;
}
.div1 div img:hover, .div2 div img:hover, .div3 div img:hover, .div4 div img:hover{
    transform: scale(1.05);
}
.parent a div p {
    padding: 5px;
    background: #806b00;
    color: #fff;
    font-size: 14px;
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
}
@media (max-width: 900px) {

}
@media (max-width: 700px) {

}
@media (max-width: 480px) {
    .parent {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
    }

    .div1 { grid-area: 1 / 1 / 3 / 6; }
    .div2 { grid-area: 3 / 1 / 5 / 6; }
    .div3 { grid-area: 5 / 1 / 7 / 6; }

    .parent a div p{
        font-size: 12px;
    }
    .ServicesBG{
        height: 175px;
    }
    .ServicesBG h3{
        font-size: 24px;
    }
    .ServicesBG:after{
        height: 100%;
    }
}