@font-face {
    font-weight: 400;
    font-family: Aharoni;
    src: url("../../assets/font/ahronbd.ttf");
}
.avrasya-portal img{
   overflow: hidden;
    transition: all 0.5s ease;
}
.avrasya-portal img:active{
    transform: scale(1.05);
}
.language-select{

    border-radius: 5px;
    background: black;
    color: #fff;
    margin-left: 30px;
}
.language-select option {
    text-align: center;
}
.header{
    margin-bottom: 80px;

}
nav {
    z-index: 99;
    width: 100%;
    background: #242526;
    position: fixed;
    top: 0;
}

nav .wrapper {
    position: relative;
    max-width: 1260px;
    padding: 0 30px;
    height: 80px;
    line-height: 80px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo{
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo img{
    width: 64px;
    height: 64px;
    margin-right: 20px;
}
.wrapper .logo p {
    font-family: "Aharoni", sans-serif;
    text-align: center;
    color: #cbb082;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 600;
    text-decoration: none;
    line-height: 28px;
}
.logo span{
    font-family: "Times New Roman", sans-serif;
    font-size: 18px;
}
.wrapper .nav-links {
    display: inline-flex;

}

.nav-links li {
    list-style: none;
}

.nav-links li a {
    color: #f2f2f2;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    padding: 9px 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.nav-links li a:hover {
    background: #3A3B3C;
}

.nav-links .mobile-item {
    display: none;
}

.nav-links .drop-menu {
    position: absolute;
    background: #242526;
    width: 180px;
    line-height: 45px;
    top: 80px;
    opacity: 0;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
    z-index: 100;
    transition: all 0.3s ease;
    top: 80px;
    opacity: 1;
    visibility: visible;
}
.drop-menu li a {
    width: 100%;
    display: block;
    padding: 0 0 0 15px;
    font-weight: 400;
    border-radius: 0;
}

.mega-box {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0 30px;
    top: 60px;
    opacity: 0;
    visibility: hidden;
}

.mega-box .content {
    background: #242526;
    padding: 25px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    z-index: 100;

}
.row{
    overflow: hidden;
}
.references-header{
    text-align: center;
}
.mega-box .content .row {
    width: calc(25% - 30px);
    line-height: 45px;
}

.content .row img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content .row header {
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
}

.content .row .mega-links {
    margin-left: -40px;
    border-left: 1px solid rgba(255, 255, 255, 0.09);

}

.row .mega-links li {
    padding: 0 20px;

}

.row .mega-links li a {
    padding: 0 20px;
    color: #d9d9d9;
    font-size: 16px;
    display: block;
}

.row .mega-links li a:hover {
    background: none;
    color: #cbb082;

}

.wrapper .btn {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    display: none;
}

.wrapper .btn.close-btn {
    position: absolute;
    right: 30px;
    top: 10px;
}

@media screen and (max-width: 970px) {
    .row{
        visibility: hidden;
    }

    .avrasya-portal{
        margin-top: 10px;
    }
   .row img{
   }
    .wrapper .btn {
        display: block;
    }

    .wrapper .nav-links {
        position: fixed;
        height: 100vh;
        width: 100%;
        max-width: 350px;
        top: 0;
        left: -100%;
        background: #242526;
        display: block;
        padding: 50px 10px;
        line-height: 50px;
        overflow-y: auto;
        box-shadow: 0 15px 15px rgba(0, 0, 0, 0.18);
        transition: all 0.3s ease;
        z-index: 999;
    }

    /* custom scroll bar */
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #242526;
    }

    ::-webkit-scrollbar-thumb {
        background: #3A3B3C;
    }
    .menu-btn img, .fas img{
        width: 32px;
        height: 32px;
    }
    #menu-btn:checked ~ .nav-links {
        left: 0;
    }

    #menu-btn:checked ~ .btn.menu-btn {
        display: none;
    }

    #close-btn:checked ~ .btn.menu-btn {
        display: block;
    }

    .nav-links li {
        margin: 15px 10px;
    }

    .nav-links li a {
        padding: 0 20px;
        font-size: 20px;
    }
    .row .mega-links li a{
        font-size: 14px;
    }

    .nav-links .drop-menu {
        position: static;
        opacity: 1;
        top: 45px;
        visibility: visible;
        padding-left: 20px;
        width: 100%;
        box-shadow: none;
        transition: all 0.3s ease;
    }

    #showDrop:checked ~ .drop-menu,
    #showMega:checked ~ .mega-box,
    #aboutUs:checked ~ .drop-menu,
    #aboutUs:checked ~ .mega-box,
    #aboutUs:checked ~ .mega-box .row,
    #services:checked ~ .drop-menu,
    #services:checked ~ .mega-box,
    #services:checked ~ .mega-box .row,
    #corporate:checked ~ .drop-menu,
    #corporate:checked ~ .mega-box,
    #corporate:checked ~ .mega-box .row,
    #references:checked ~ .drop-menu,
    #references:checked ~ .mega-box,
    #references:checked ~ .mega-box .row,
    #contacts:checked ~ .drop-menu,
    #contacts:checked ~ .mega-box,
    #contacts:checked ~ .mega-box .row
    {
        visibility: visible;
        max-height: 100%;
    }


    .nav-links .desktop-item {
        display: none;
    }

    .nav-links .mobile-item {
        display: block;
        color: #f2f2f2;
        font-size: 20px;
        font-weight: 500;
        padding-left: 20px;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.3s ease;
    }

    .nav-links .mobile-item:hover {
        background: #3A3B3C;
    }

    .drop-menu li {
        margin: 0;
    }

    .drop-menu li a {
        border-radius: 5px;
        font-size: 18px;
    }

    .mega-box {
        position: static;
        top: 65px;
        opacity: 1;
        visibility: visible;
        padding: 0 20px;
        max-height: 0;
        transition: all 0.3s ease;
    }

    .mega-box .content {
        box-shadow: none;
        flex-direction: column;
        padding: 20px 20px 0 20px;
    }

    .mega-box .content .row {
        width: 100%;
        margin-bottom: 15px;
        border-top: 1px solid rgba(255, 255, 255, 0.08);
    }

    .mega-box .content .row:nth-child(1),
    .mega-box .content .row:nth-child(2) {
        border-top: 0;
    }

    .content .row .mega-links {
        border-left: 0;
        padding-left: 15px;
    }

    .row .mega-links li {
        margin: 0;
    }

    .content .row header {
        font-size: 19px;
    }
}

nav input {
    display: none;
}
@media (max-width: 400px) {
    .logo p{
        display: none;
    }
}

select:focus{
    background: #3A3B3C;
}