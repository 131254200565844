.Loader{
    background: url('../../assets/img/Loader/Loader.jpg');
    background-size: cover;
    height: calc(100vh - 271px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinner {
    animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

.path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite; /* 1.4s * 4 = 5.6s */
}

@keyframes colors {
    0% { stroke: #4285F4; }
    25% { stroke: #DE3E35; }
    50% { stroke: #F7C223; }
    75% { stroke: #1B9A59; }
    100% { stroke: #4285F4; }
}

@keyframes dash {
    0% { stroke-dashoffset: 187; }
    50% {
        stroke-dashoffset: 46.75; /* 187 / 4 */
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform: rotate(450deg);
    }
}