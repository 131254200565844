.Recruitment{
    padding-bottom: 30px;
}
.RecruitmentBG{
    height: 250px;
    width: 100%;
    background: url("../../assets/img/Recruitment/RecruitmentBG.webp") center no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}
.RecruitmentBG:after{
    position: absolute;
    content: '';
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.RecruitmentBG h3{
    z-index: 1;
    margin-left: 11%;
    color:white;
    font-size: 36px;
}


.Text{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 30px;
    border-bottom: 1px solid gray;
}

.Text p{
    text-align: justify;
    max-width: 750px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.TextImgs img{
    margin-top: 15px;
    width: 380px;
    height: 250px;
}
@media (max-width: 480px) {
    .RecruitmentBG{
        height: 175px;
    }
    .RecruitmentBG h3{
        font-size: 24px;
    }
    .RecruitmentBG:after{
        height: 100%;
    }
}
@media (max-width: 1000px) {
    .Text{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 480px) {
        .TextImgs img{
            height: 150px;
        }
}