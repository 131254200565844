.HrConsultancyBG img{
    width: 100%;
    height: 100vh;
}

@media (max-width: 768px) {
    .HrConsultancyBG img{
        width: 100%;
        height: 50vh;
    }
}
@media (max-width: 480px) {
    .HrConsultancyBG img{
        width: 100%;
        height: 30vh;
    }
}