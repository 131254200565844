.Switcher{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 100px;
    background: #171616;
}
.Switcher li button{
    width: 150px;
    height: 40px;
    border-radius: 20px;
    border: none;
    background: #171616;
    color: white;
    transition: all 0.7s ease;
}
.Switcher li button:hover{
    cursor: pointer;
    transform: scale(1.05);
    color: black;
    background: white;
}