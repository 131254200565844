
.swiper-container{
    z-index: 0 !important;

}
.swiper-wrapper {
    width: 100%;
    height: 420px;
}

.swiper-background_4 {
    background: url("../../assets/img/opportunityBG.jpg");
    background-size: cover;
}
.swiper-background_3{
    background: url("../../assets/img/ourServices.webp");
    background-size: cover;
}
.swiper-background_2{
    background: url("../../assets/img/ourPartners.webp");
    background-size: cover;
}
.swiper-background_1{
    background: url("../../assets/img/ourReferences.webp");
    background-size: cover;
}

.swiper-background {
    padding-top: 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.swiper-background a {
    color: #cbb082;
    font-size: 24px;
}

.swiper-title {
    font-size: 54px;
    color: #cbb082;

}
.swiper-text{
    padding: 0 10px;
    background: rgba(0, 0, 0, 0.5);

}
.swiper-desc {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    max-width: 700px;
}

.swiper-link {
    position: relative;
}

.swiper-link:after {
    content: '❯';
    position: absolute;
    top: 0;
    right: -20px;
}
@media (max-width: 768px) {
 .swiper-title{
     font-size: 40px;
 }
    .swiper-desc, .swiper-link{
        font-size: 20px;
    }
}
@media (max-width: 576px){

    .swiper-text{
        text-align: center;
    }
    .swiper-title{
        font-size: 28px;
    }
    .swiper-desc, .swiper-link{
        font-size: 16px;
    }
}