.CodeOfProduct{
    padding-bottom: 50px;
}
.CodeOfProductBG{
    height: 250px;
    width: 100%;
    background: url("./CodeOfConductBG.webp") center no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}
.CodeOfProductBG:after{
    position: absolute;
    content: '';
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.CodeOfProductBG h3{
    z-index: 1;
    margin-left: 11%;
    color:white;
    font-size: 36px;
}

.Wrapper{
    display: flex;
    align-items: center;
    gap: 40px;
}
.pdfLink .Text{
    width: 50%;
}

.linkImg{
    width: 70%;
}
.Text{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: justify;
    width: 70%;
    padding-bottom: 30px;
}
.Text h4{
    margin-top: 20px;
}
.Text p{
    margin-top: 10px;
}

.subtitle{
    margin: 30px 0 10px 0;
}
.subDesc{
    font-size: 16px;
    padding-bottom: 20px;
}
.conductList {
    border-top: 1px solid rgb(128, 128, 128);
    border-bottom: 1px solid rgb(128, 128, 128);
}

.conductList li{
    margin: 40px 0px 0px 20px;
    position: relative;
}
.conductList li:before{
    content: '✔';
    color: #26ff26;
    position: absolute;
    top: 0;
    left: -20px;
}

.conductList li:last-child {
    padding-bottom: 50px;
}

@media (max-width: 900px) {



    .Wrapper{
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h4{
            font-size: 14px;
        }
        p, li{
            font-size: 13px;
        }

        .conductList li{
            margin: 20px 0px 0px 0px;
        }
    }

}

@media (max-width: 560px) {
    .pdfLink{
        width: 90%;
        height: 50%;
    }

}