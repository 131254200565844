.Individuals{
    padding-bottom: 50px;
}
.IndividualsBG{
    height: 250px;
    width: 100%;
    background: url("../../assets/img/RelationshipBased/RelationshipBasedBG.webp") center no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}
.IndividualsBG:after{
    position: absolute;
    content: '';
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.IndividualsBG h3{
    z-index: 1;
    margin-left: 11%;
    color:white;
    font-size: 36px;
}

.Desc{
    max-width: 800px;
}
.Text{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 30px;
    border-bottom: 1px solid gray;
}
.Text h4{
    margin-top: 20px;
}
.Text p{
    margin-top: 10px;
}

.descImg{
    margin-top: 40px;
    height: 60%;
    width: 50%;
}
@media (max-width: 760px) {
    .descImg{
        width: 100%;
    }
}
@media (max-width: 480px) {
    .IndividualsBG{
        height: 175px;
    }
    .IndividualsBG h3{
        font-size: 24px;
    }
    .Title{
        text-align: center;
    }
    .Desc {
        font-size: 14px;
    }
    .IndividualsBG:after{
        height: 100%;
    }
}