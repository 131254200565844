.Hospitality {
    padding-bottom: 30px;
}

.HospitalityBG {
    height: 250px;
    width: 100%;
    background: url("../../assets/img/OurReferences/HospitalityBG.webp") center no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}

.HospitalityBG:after {
    position: absolute;
    content: '';
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.HospitalityBG h3 {
    z-index: 1;
    margin-left: 11%;
    color: white;
    font-size: 36px;
}

.List {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 20px;
    justify-content: space-between;
}

.Item {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 10px 15px;
    flex-basis: 350px;
    border: 1px solid gray;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    transition: all 1s ease;
}

.Item:hover{
    box-shadow: 0px 6px 13px 5px rgba(0,0,0,0.33);
    -webkit-box-shadow: 0px 6px 13px 5px rgba(0,0,0,0.33);
    -moz-box-shadow: 0px 6px 13px 5px rgba(0,0,0,0.33);
    transform: scale(1.03);
}

.Item img{
    width: 100%;
    height: 150px;
    max-height: 150px;
    border-radius: 8px;
}
.Title {
    margin-top: 20px;
}

.Desc {
    margin-top: 10px;
}