.about{
    padding: 30px 0;
}
.about__wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.about__link a{
    padding: 5px;
    color: #cbb082;
    border: 1px solid #cbb082;
    border-radius: 20px;

}
.about__link a span{
    transition: all 0.7s ease;
}
.about__link a:hover span{
    color: #b07f38;
}

.about__desc{
    text-align: center;
    font-size: 20px;
}
.parent{
    padding: 30px 0;
}
.parent__wrapper {
    position: relative;
    overflow: hidden;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 2fr 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
}

.div1 { grid-area: 1 / 1 / 2 / 3; }
.div2 { grid-area: 1 / 3 / 2 / 4; }
.div3 { grid-area: 1 / 4 / 2 / 5; }
.div4 { grid-area: 2 / 1 / 3 / 3; }
.div5 { grid-area: 2 / 3 / 3 / 5; }

.div1 div span, .div2 div span, .div3 div span, .div4 div span, .div5 div span{
    padding: 5px;
    background: #806b00;
    color: #fff;
    font-size: 14px;
}
.div1 div p, .div2 div p, .div3 div p, .div4 div p, .div5 div p{
    font-weight: 700;
    color: #fff;

}

.career-path, .project, .report, .news, .news-2{
    position: relative;
    overflow: hidden;
}
.career-path div img, .project div img, .report div img, .news div img, .news-2 div img{
    content: '';
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    transition: all 0.7s ease;
}
.career-path div img:hover, .project div img:hover,
.report div img:hover, .news div img:hover,
.news-2 div img:hover{
    transform: scale(1.05);
}
.grid__text{
    content: '';
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.media__inner{
    display: flex;
    gap: 30px;
}
.media-card {
    height: 40vh;
    width: 100%;
    position: relative;
    color: #fff;
    font-weight: 700;
}
.media-card:hover .media-card-filter{
    opacity: 0.45;
}
.media-card-filter{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0.6;
    transition: all 0.7s ease;

}
.media-card div img{
    content: '';
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;

}
.media-card__img{
    content: '';
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

}
.media__name{
    content: '';
    color: #cbb082;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
}
.media-card__title{
    content: '';
    font-size: 24px;
    position: absolute;
    top: 70px;
    left: 20px;
}
.media-card__desc{
    content: '';
    font-size: 18px;
    position: absolute;
    top: 130px;
    left: 20px;
    z-index: 1;

}
.media-card a{
    content: '';
    position: absolute;
    font-size: 16px;
    right: 40px;
    bottom: 20px;
}
.media-card a:after{
    content: "❯";
    margin-left: 10px;
}

@media (max-width: 768px) {
    .media-card{
        height: 30vh;
    }
    .media-card__title{
        font-size: 20px;
        top: 50px;
        line-height: 18px;
    }
    .media-card__desc{
        font-size: 14px;
        top: 90px;
    }
    .parent__wrapper {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 2fr 1fr 1.5fr 2fr;
    }

    .div1 { grid-area: 1 / 1 / 3 / 3; }
    .div2 { grid-area: 1 / 3 / 3 / 5; }
    .div3 { grid-area: 3 / 1 / 4 / 3; }
    .div4 { grid-area: 3 / 3 / 4 / 5; }
    .div5 { grid-area: 4 / 1 / 5 / 5; }
}
@media (max-width: 576px) {
    .media-card__title{
        font-size: 16px;
    }
    .media-card__desc{
        font-size: 12px;
    }
}
@media (max-width: 480px) {
    .parent__wrapper{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 3fr) repeat(2, 4fr) 8fr;
    }
    .div1 { grid-area: 1 / 1 / 3 / 5; }
    .div2 { grid-area: 3 / 1 / 4 / 5; }
    .div3 { grid-area: 4 / 1 / 5 / 5; }
    .div4 { grid-area: 5 / 1 / 6 / 3; }
    .div5 { grid-area: 5 / 3 / 6 / 5; }

    .media__inner{
        flex-direction: column;
    }
}