.DemandForm{
    padding: 40px 0;
    text-align: center;
    margin: 0 auto;
    height: 100%;
}
.DemandForm h3{
    margin-bottom: 20px;
}
.Form{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 10px;
    max-width: 300px;
}
.Form input{
    padding: 0 5px;
}
.Phone{
    display: flex;
}
.Phone select {
    font-size: 12px;
    font-weight: 700;
    width: 40%;
}
.Phone input{
    width: 60%;
}

.requestedWorkers{
    display: flex;
    gap: 10px;
}
.requestedWorkers input{
    width: 70%;
}
.requestedWorkers input:last-child{
    width: 30%;
}
.Form textarea{
    font-size: 16px;
    height: 200px;
    width: 300px;
    max-width: 500px;
    align-self: center;
}
.Form button{
    margin-top: 20px;
}
.ant-btn{
    width: 100%;
}