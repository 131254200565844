.Solutions{
    padding-bottom: 30px;
}
.SolutionsBG{
    height: 250px;
    width: 100%;
    background: url("../../assets/img/Solutions/Solutions.webp") center no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}
.SolutionsBG:after{
    position: absolute;
    content: '';
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.SolutionsBG h3{
    z-index: 1;
    margin-left: 11%;
    color:white;
    font-size: 36px;
}


.Text{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 30px;
    border-bottom: 1px solid gray;
}
.Text h4{
    font-size: 20px;
    margin-top: 20px;
}
.Text p{
    margin-top: 10px;
}
.Flex{
    display: flex;
    align-items: center;
    gap: 40px;
    margin-top: 30px;

}
.FlexText{
    width: 70%;
}
.FlexText p a{
    text-decoration: underline;
}
.FlexText p a:hover{
    transition: all 0.2s ease-in;
    text-decoration: none;
    color: #ffd600;
}
.FlexImg{
    width: 40%;
}

.Flex2{
    flex-direction: row-reverse;
}
.LinkUnderline{
    text-decoration: underline;
}
.LinkUnderline:hover{
    transition: all .3s ease-in;
    color: #806b00;
}
.FlexTitle{
    font-size: 16px;
}
@media (max-width: 900px) {
    .Flex{
        font-size: 15px;
        align-items: center;
    }
}
@media (max-width: 700px) {
    .Flex{
        flex-wrap: wrap;
        justify-content: center;
    }
    .FlexTitle{
        text-align: center;
    }
}
@media (max-width: 480px) {
    .SolutionsBG{
        height: 175px;
    }
    .SolutionsBG h3{
        font-size: 24px;
    }
    .Desc {
        font-size: 15px;
    }
    .FlexTitle{
        font-size: 14px;
    }
    .FlexText p{
        font-size: 13px;
    }
    .SolutionsBG:after{
        height: 100%;
    }
}