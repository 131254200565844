.Mission{
    padding-bottom: 50px;
}
.MissionBG{
    height: 250px;
    width: 100%;
    background: url("../AboutUs/aboutUsPageBG.webp") center no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    margin-bottom: 50px;
}
.MissionBG:after{
    position: absolute;
    content: '';
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.MissionBG h3{
    z-index: 1;
    margin-left: 11%;
    color:white;
    font-size: 36px;
}
.Wrapper{
    text-align: justify;
}
.Wrapper p{
    margin-top: 10px;
}
.Wrapper h4{
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
}
.Wrapper span{
    font-weight: 700;
}