.about {
    padding-bottom: 50px;
}

.aboutBG {
    height: 250px;
    width: 100%;
    background: url("./aboutUsPageBG.webp") center no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    margin-bottom: 50px;
}

.aboutBG:after {
    position: absolute;
    content: '';
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.aboutBG h3 {
    z-index: 1;
    margin-left: 11%;
    color: white;
    font-size: 36px;
}

.aboutWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.aboutText {
    display: flex;
    flex-direction: column;
    text-align: justify;
}

.aboutText h4 {
    margin-top: 20px;
}

.aboutText p {
    font-size: 16px;
    margin-top: 10px;
}

.parent {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    height: 40vh;
}

.div1 {
    grid-area: 1 / 1 / 2 / 3;
}

.div2 {
    grid-area: 1 / 3 / 2 / 4;
}

.div3 {
    grid-area: 2 / 1 / 3 / 2;
}

.div4 {
    grid-area: 2 / 2 / 3 / 4;
}

.div1, .div2, .div3, .div4 {
    position: relative;
    overflow: hidden;
}

.div1 img, .div2 img, .div3 img, .div4 img {
    content: '';
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    transition: all 0.7s ease;
}
.div1 div img:hover, .div2 div img:hover, .div3 div img:hover, .div4 div img:hover{
    transform: scale(1.05);
}
.parent a div p {
    padding: 5px;
    background: #806b00;
    color: #fff;
    font-size: 14px;
    content: '';
    position: absolute;
    bottom: 20px;
    left: 20px;
}