
/* FORM STYLE */
.formWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

}
.portalImg{
    width: 40%;
    height: 150px;
}
.loginFormBD {
    background: url("../../assets/img/PortalBG.webp");
    background-size: cover;
    color: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.formContainer {
    font-size: 1.125rem;
    background: #1b1a1a;
    padding: 1rem 1rem;
    border-radius: 20px;
    margin: 5rem 28.125rem;
    box-shadow: 3px 0.25rem 1.25rem rgba(27, 27, 27, 0.2);
    display: flex;
    justify-content: center;
    gap: 40px;
    align-items: center;
}


.formContainer a {
    text-decoration: none;
    color: #e2dc20;
}

.loginBtn {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    background: #e2dc20;
    padding: 0.938rem;
    font-family: inherit;
    font-weight: 500;
    font-size: 1.563rem;
    color: #0d0f42;
    border: 0;
    border-radius: 0.313rem;
    margin-bottom: 1.25rem;
}

.loginBtn:focus {
    outline: 0;
}

.loginBtn:active {
    transform: scale(0.98);
}

.text {
    margin-top: 0.938rem;
}

.formControl {
    position: relative;
    margin: 1.25rem 0 2.5rem;
}

.formControl input {
    background: transparent;
    border: 0;
    border-bottom: 1px #fff solid;
    display: block;
    width: 100%;
    padding: 1.25rem 0;
    font-size: 1.125rem;
    color: #fff;
}

.formControl input:focus {
    outline: 0;
    border-bottom-color: #c2c25a;
}

.formControl label {
    position: absolute;
    top: 0.938rem;
    left: 10px;
}

.formControl label span {
    display: inline-block;
    font-size: 1.125rem;
    min-width: 0.313rem;
    transition: 0.3s cubic-bezier(0.53, 0.246, 0.265, 1.66);
}

.formControl input:focus + label span,
.formControl input:valid + label span {
    color: #c2c25a;
    transform: translateY(-1.875rem);
}
@media (max-width: 1280px) {
    .formContainer{
        min-width: 50%;
    }
}
@media (max-width: 768px) {
    .portalImg{
        width: 60%;
    }
    .formContainer{
        flex-direction: column;
        padding: 2.5rem 1rem;
        font-size: 1rem;
    }

    .formContainer label span{
        font-size: 1rem;
    }
}
@media (max-width: 480px) {
    .portalImg{
        width: 60%;
        height: 60px;
    }
    .formControl{
        margin: 1rem 0 1rem;
    }
    .formControl input{
        padding: 1rem 0;
    }
    .loginBtn{
        padding: 0;
        font-size: 1.25rem;
        margin-bottom: 0;
    }
}