.footer{
    color: #fff;
    padding: 55px 0;
    background: #242526;

}
.footerWrapper{
    display: flex;
    justify-content: space-between;

}
.footerText{
    display: flex;
    gap: 40px;
}
.footerText:last-child{
    align-self: flex-end;
}
.footer__links{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 5px;
}
.footer__links li a, .footerLogo a{
    transition: all 0.3s ease;

}
.footer__links li a:hover, .footerLogo a:hover{
    color: #cbb082;
}
.footerLogo p span{
    border-left: 3px solid #fff;
    padding-left: 15px;
    margin-left: 15px;
}
@media (max-width: 768px){
    .footerWrapper{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .footer__links{
        font-size: 14px;
    }
}
@media (max-width: 576px) {
    .footerText{
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }
}